.header {
    border: 0;
    
    .container {
        padding:0;
        .navbar-header {
            margin-top:15px;
        }
    }
    @media (max-width: $screen-md) {
       .container {
            width: 100%;
            padding-left:2%;
        } 
    }
    position: relative;
    text-transform: uppercase;
    
    .header_top {
        
        position: relative;
        
        min-height: $navbar-top-height;
        overflow: auto;
        
        .row {
            margin-right:0px;
            margin-left:0px;
            .top-left {
                background: $navbar-top-bg;
                text-align: left;
                padding: 5px 0px;
                height:40px;
                #menu-top-navigation {
                  margin: 0;
                  padding: 0;
                  li.menu-item {
                    display: inline;
                    
                    a {
                      color: color('black');
                      @include font-size(14);
                      line-height:30px;
                      padding-left:5%;

                      &:hover,
                          &:focus {
                              text-decoration: none;
                              color:#0F0000;
                              background-color: transparent;
                          } 
                    }
                    @media (max-width: $screen-sm) {
                        a {
                            padding-left:5px;
                            @include font-size(18);
                        }
                    }
                    @media (max-width: $screen-xs) {
                        a {
                            padding-left:5px;
                            @include font-size(14);
                        }
                    }
                  }
                  
                }
                
                
            }

            .top-right {
                padding: 5px 0px;
                text-align: left;
                a {
                    color: color('blue'); 
                    padding-left: 12%;
                    .fa {
                        background-color: color('blue');
                        color: #fff;
                    }
                    &:hover, &:focus, &:active {
                      background-color: transparent;
                    }
                }
                @include font-size(14);
            }
        }
        
    }
    
    .header-bottom {
        overflow: auto;
        background-color: $navbar-bottom-bg;
        height: 80px;
        @include font-size(18);
        letter-spacing:1px;
        div {
             overflow: auto;
            background-color: $navbar-bottom-bg;
            height: 80px;
            @include font-size(18);
            letter-spacing:1px;
            i {
                color:color('gray');
            }
            a {
                color:color('black');
                text-transform: capitalize;
                font-size: 1.5rem;
            }
            @media (max-width: $screen-sm) {
                float:none !important;
            }

        }
    }
    @media (max-width: $screen-sm) {
        .header-bottom {
            overflow: inherit;
            height:auto;
        }
    }
    &.navbar {
        margin-bottom:0px;
        
        .navbar-header {
            float:left;
            //logo
            .navbar-brand {
                
            }
        }
        @media (max-width: $screen-sm) {
            .navbar-header {
                float:none;   
                position: relative;
                width: 100%;
                margin-right: 0;
                margin-left: 0;
                button.navbar-toggle {
                    position: absolute;
                    right: 15px;
                    top: 35%;
                    float: none;
                    margin-right: 0;
                }
                a.navbar-brand {
                    display: block;
                    text-align: center;
                    float: none;
                    img {
                        margin: 0 auto;
                    }
                }
            }
        }
        .navbar-collapse {
            //actual menu text <ul>
            
            #menu-header {
                
                padding-left: 2.5%;
                width: 100%;
                //float left or right
                li:not(:first-child):before {
                    content: " | ";
                    color: color('blueLight');
                }
                @media (max-width: $screen-sm) {
                    li:not(:first-child):before {
                        content: "";
                    }
                }
                > li {
                    padding-bottom:0px;
                    float:none;
                    display:inline;
                    > a {
                        display:inline;
                        @include font-size(15);
                        font-family: $nav-font-family;
                        line-height: 80px;
                        font-weight:600;
                        background-color: transparent;
                        
                        &:hover,
                        &:focus {
                            background-color: transparent;
                        }      
                    }
                    
                }
                @media (max-width: $screen-sm) {
                    > li {
                        display:block;
                        > a {
                            line-height:28px;
                        }
                    }
                    
                }

            }
            
            @media (min-width: $screen-sm) and (max-width: 880px) {
                #menu-header {
                    padding-left: 1.25%;
                    li:not(:first-child):before {
                        content:"";
                    }
                    > li {
                        a {
                            padding-left: .5%;
                            padding-right: .5%;
                        }
                    }
                     
                 } 
             }
        }
    }
}


@media (max-width: $screen-sm) {
    .header {
        .container {
            padding-left:0;
        
            .header_top {
                border-bottom: none;
                border-bottom: none;
                width: 100%;
                .row {
                    .top-left, .top-right, .top-middle {
                        text-align: center;

                    }
                    .top-left {
                        text-align: center;
                    }
                    .top-right {
                       background-color: color('blueLight');
                       a {
                           padding-left:0;
                           .fa {
                                background-color: transparent;
                                padding-bottom: 30px;
                           }
                           i.fa-social-circle {
                               width: 18px;
                           }
                       }
                    }
                }

            }
            .header-bottom{
                .navbar-collapse {
                    #menu-header {
                        padding-left: 10px;
                        width: 100%;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }
    .navbar-nav>li>a {
        line-height:inherit;
    }
}