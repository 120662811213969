// Add Google Fonts
@import 'https://fonts.googleapis.com/css?family=PT+Sans:400,700';
* {
    margin: 0;
    padding: 0;
}

html{font-size: 45%;}

body{
  text-rendering:optimizeLegibility; 
}
h1{
    @include font-size($h1-font-size);
    color: $h1-color;
    margin-top:2.5rem;
}

h2{
    @include font-size($h2-font-size);
    @include text-padding(0,0,10);
    color: $h2-color;
    margin-top:2rem;
}

h3{
    @include font-size(24);
    font-weight: 200;
    color: $h3-color;
}
h4{
    @include font-size(20);
    font-weight: 600;
    color: $h3-color;
}
p{
    @include font-size(16);
    line-height: $base-line-height;
}
ul {
    margin-top:10px;
    margin-bottom:15px;
}
li{
    @include font-size(18);
    @include padding(bottom,5);
    font-weight:300;
    list-style: none;
    line-height: $base-line-height;
    color: #00b6f1;
    line-height:16px;
}

.container{
  @include text-padding(10,10);
}

section{
  border-top:1px solid black;
  border-bottom:1px solid #ddd;
  @include text-padding(30);
}
/*
.example{
    background: url('http://i.imgur.com/KBfRhVq.png');
  box-shadow: -1px -1px 5px #000;
}
.example-heading{
  @include text-padding(10,10,5,10);
  background: rgba(201, 104, 104, 0.7);
  color: white;
}
.example-body{
  @include text-padding(20);
  margin-bottom: 20px;
}*/

@media (min-width: 768px) {
    html{font-size: 55%;}
  
    p{
      @include padding(bottom,15);
      line-height: 1.5em;
    }

    li{
      @include padding(bottom,10);
    }
}

@media (min-width: 992px) {
    html{font-size: 62.5%;}
}

@media (min-width: 1200px) {
    html{font-size: 72%;}
}
