//**
// All Variables Used Throughout
//**

// These comments won't appear in compiled code so use these as much as possible.
//* The other comments if you remove one "/" from the beginning appear in compiled code (Use Sparingly)
// Glyphicons font path
$icon-font-path:        "../fonts/";
// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

//== Colors
//

//* this is called a SASS map and can be used in code as .bg-white and .text-white
$mainColors: (
	'white': #ffffff,
	'black': #010000,
	'orange':#ff6633,
	'green': #339933,   
	'blue':  #3953a0, 
        'blueLight':  #76c5f0,
        'blueTeal':   #00aeef,
	'gray':  #e5e5e5,
        'grayLighter': #eee,
        'grayLight': #999999,
        'grayDark': #262626,
        'grayDarker': #262626,
);
// build .text-color and .bg-color classes
@each $name, $value in $mainColors {
  @include text-color($name, $value);
  @include bg-color($name, $value);
}

//## Gray and brand colors for use across Bootstrap.
$brand-primary:         #e5e5e5;

//** Background color for `<body>`.
$body-bg:               #ffffff;

//** Global text color on `<body>`.
$text-color:            #262626;

//** Global textual link color.
$link-color:            #3953a0;

$headings-color:          $link-color;
$h1-color:                $headings-color;
$h2-color:                $headings-color;
$h3-color:                $headings-color;
//== Typography (COPIED FROM BOOTSTRAP VARIABLES)
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  'PT Sans', sans-serif, Arial;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-base:        $font-family-sans-serif;

$base-font-size:          16px;
$h1-font-size:            32;

$h2-font-size:            26;
$h3-font-size:            20;
$base-line-height:        1.4em;
$base-pad-size:           20;


$font-size-base:          $base-font-size;
$font-size-large:         ceil(($font-size-base * 1.25)); // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)); // ~12px

$headings-font-family:    inherit;
$headings-font-weight:    600;
$headings-line-height:    1.1;


//== Navbar 
//
$navbar-height:                    auto;
$navbar-top-height:                10px;
$navbar-bottom-height:             80px;
$navbar-default-bg:                #ffffff;
//$navbar-default-border:            darken($navbar-default-bg, 6.5%);
$navbar-default-border:            inherit;
$navbar-top-border:                #ffffff;
$navbar-top-bg:                    #ffffff;
$navbar-bottom-bg:                 #ffffff;

// Navbar links
$navbar-default-link-color:                #3a53a3;
$navbar-default-link-hover-color:          darken($navbar-default-link-color, 10%);
$navbar-default-link-hover-bg:             transparent;
$navbar-default-link-active-color:         darken($navbar-default-link-color, 10%);
$navbar-default-link-active-bg:            darken($navbar-default-link-color, 10%);
$navbar-default-link-disabled-color:       #e5e5e5;
$navbar-default-link-disabled-bg:          transparent;

// Navbar brand label
$navbar-default-brand-color:               $navbar-default-link-color;
$navbar-default-brand-hover-color:         darken($navbar-default-brand-color, 10%);
$navbar-default-brand-hover-bg:            transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg:           #ddd;
$navbar-default-toggle-icon-bar-bg:        #888;
$navbar-default-toggle-border-color:       #ddd;

//=== Shared nav styles
$nav-link-padding:                          10px 15px;
$nav-link-hover-bg:                         #ccc;

//$nav-font-size:                             1.75rem; need to change in _headers.scss file
$nav-font-family:                           inherit;


//=== Footer Colors
$footer-default-bg:  color('white');
$footer-default-bottom-bg:  color('gray');
$footer-default-color:  color('grayDark');
$footer-default-bottom-color:  color('grayDark');
$footer-default-font-size:  $font-size-small;


/*
 * => FONT VARS
 * ---------------------------------------------------------------------------*/
 // Declare your fonts in _fonts.scss
$btn-default-color:     color('white');
$btn-default-bg:                 color('blueTeal');
$btn-default-border:             color('blue');
$btn-nav-bg:    color('blue');
$btn-font-weight: normal;
$btn-primary-color: color('white');
$btn-primary-bg:  color('blueTeal');
$btn-border-radius: 0;
$btn-font-size: $font-size-large;

$border-radius-base:        0;
$border-radius-large:       6px;
$border-radius-small:       3px;