.footer {
    
    min-height: 70px;
    padding-top: 25px;
    border-top: 0;
    bottom:500px;
    background:$footer-default-bg;
    color: $footer-default-color;
    font-size: $footer-default-font-size;
    
    .footer_top {
        
        .container {
            
            .row {
                line-height: 100px;
                
                .col {
                    text-align: center;
                }
            }
        }
        padding-bottom: 26px;
        
        .footer-nav {
            float:left;

            ul {
                margin-bottom: 0;
                > li {
                    display:inline-block;
                    a {
                        text-decoration:none;
                        padding-left:6px;
                        padding-right:6px;
                        color:$link-color;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    + li {
                        &:before {
                            content: " | ";
                        }
                    }
                    &.current-menu-item {
                        a {
                            color: $link-color;
                        }
                    }
                }
            }
        }
        
        .footer-social {
            a {
                font-size: 18px;
                display: inline-block;
                margin: 0 6px;
                padding: 2px;

                &:hover {
                    -webkit-animation-duration: 1s; 
                    animation-duration: 1s; 
                    -webkit-animation-fill-mode: both; 
                    animation-fill-mode: both;
                    -webkit-animation-name: pulse; 
                    animation-name: pulse; 
                }
            }
        }
    }
    
    .footer-bottom {
        font-size:$footer-default-font-size;
        padding-top: 12px;
        padding-bottom: 12px;
        background:$footer-default-bottom-bg;
        .footer-contact-info {
            padding-left: 20px;
            border-left: 1px solid color('blue');
            line-height: 18px;
            
        }
        
        .footer-address {
            padding-right:20px;
            line-height: 18px;
        }
        .footer-social-icons {
            margin-bottom:5px;
            
            a {
                margin-left:15px;
                .fa {
                        background-color: color('blue');
                        color: #fff;
                        @include font-size(13);
                        height: 20px;
                        line-height: 21px;
                        width: 20px;
                    }
            }
            
        }
        @media (max-width: $screen-sm-min) {
          .footer-contact-info, .footer-address {
            width: 50%;
          }
          .footer-social-icons {
            margin-top:20px;
          }
        }
    }
}