.navbar-default .navbar-toggle {
    background:$btn-nav-bg;
    color:$btn-default-color;
    border: inherit;
    border-radius: 0;
    .icon-bar {
        background:$btn-default-color;
            &:hover, &:focus, &:active {
            background: darken($btn-default-color, 16.5%);
        }
    }
        &:hover, &:focus, &:active {
            background: $btn-nav-bg;
        }
}

a.btn, input[type="button"], input[type="button"].btn, a.btn-primary {
    background: $btn-default-bg;
    color: color('white');
    padding: 10px 30px;
    border:0;
    border-radius: $btn-border-radius;
    font-size: $btn-font-size;
    @include font-size(18);
}