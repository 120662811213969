
/*
 * => FIXES FOR FIREFOX
 * ---------------------------------------------------------------------------*/

@-moz-document url-prefix() {  
  img.foxfix,
  .foxfix > img {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {    
  img.foxfix,
  .foxfix > img {
    max-width: 100%;
  }    
}

/*
 * => HELPER CLASSES
 * ---------------------------------------------------------------------------*/


// Margins

.margin-top-0    { margin-top: 0; }
.margin-bottom-0 { margin-bottom: 0; }
.man { margin: 0; }

// Quick floats
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

.width-100 {
  width: 100%;
}
// Toggling content
.hide {
  display: none;
}
.show {
  display: block;
}
.align-right{text-align: right;}
.align-left{text-align: left;}
.align-none{text-align: none;}
// Visibility
.invisible {
  visibility: hidden;
}

// For Affix plugin
.affix {
  position: fixed;
}

// lines (aka lines under headings)... <div class=line></div>

.line {
	border-bottom: 2px solid #444; 
	max-width: 100%;
	margin: auto;

	&.small {
		width: 500px;
	}

	&.medium {
		width: 950px;
	}

	&.white {
		border-color: #fff;
	}
}

// Vertical Align Elements
// add this to the parent element, and set a specific height for parent element
.valign-middle,
.vertical-middle,
.vertical-center {
	display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

// Bounce things
.bounce {
	animation: bounce 1s 2s infinite ease-in-out;
	position: relative;
}
.bounce-hover:hover {
	animation: bounce 1s 2s infinite ease-in-out;
	position: relative;
}
@keyframes bounce {
	0%   { top: 0; }
	40%  {}
	50%  { top: 10px; }
	55%  { top: 20px; }
	65%  { top: 10px; }
	95%  { top: 0; }
	100% { top: 0; }
}


// Tilt things
.tilt {
  transition: all .2s ease-in-out;
}
.tilt:hover {
  -ms-transform: rotate(7deg); /* IE 9 */
  -webkit-transform: rotate(7deg); /* Chrome, Safari, Opera */
  transform: rotate(7deg);
}


// Grow things
.grow { 
	transition: all .2s ease-in-out; 
}
.grow:hover { 
	-ms-transform: scale(1.1); 
	-webkit-transform: scale(1.1); 
	transform: scale(1.1); 
}

// Hover effect for block elements
.hover-effect:hover {
	box-shadow: 0 0 10px rgba(0,0,0,0.3);
}
.fa-social-circle {
    border-radius: 30px;
    display: inline-block;
    height: 26px;
    line-height: 26px;
    width: 26px;
    text-align: center;
}

.container.split-full-bg {
  padding-top: 0;
  padding-bottom: 0;
  @media (max-width: $screen-md-min) {
    .col-md-4.bg-right, .col-md-3.bg-right, .col-md-2.bg-right, .col-md-1.bg-right, .col-md-5.bg-right, .col-md-6.bg-right {
      padding-top: 1px;
      padding-bottom: 30px;
      
      .full-bg {
        width: 500%;
        width: 501vw;
        left: -100%;
      }
    }
  }
  .bg-left {
    padding-right:30px;
    padding-left:30px;
  }
  .bg-right {
    position:relative;
    padding-bottom: 30px;
    z-index:0;
    padding-left:30px;
    padding-right:30px;
    .full-bg {
      position: absolute;
      left:0;
      right:0;
      top:0;
      height:100%;
      z-index:-1;
      width: 101%;
      width:101vw;
    }
  }
  
}

.full-img-wrapper {
    position:relative;
    top: -2px;
    margin-bottom:25px;
    > div {
      position: absolute;
      bottom:0px;
      right:0;
      left:0;
      height:100%;
      
      .container {
        
      }
    }
    img {
       width: 100%;
       height:auto;
       max-height:350px;
    }
    
}
@media (max-width: $screen-xs-max) {
    .clear-xs-left {
        clear: left;
    }
    .clear-xs-right {
        clear: right;
    }
    .clear-xs-both {
        clear: both;
    }
    .pull-xs-none {
        float: none !important;
    }
    .pull-xs-left {
        float: left;
    }
    .pull-xs-right {
        float: right;
    }
    .align-xs-right {
        text-align: right;
    }
    .align-xs-left {
        text-align: left;
    }
    .align-xs-center {
        text-align: center;
    }
    .hide-xs-down {
        display: none;
    }
}
@media (max-width: $screen-sm) {
    .hide-sm-down {
        display: none;
    }
}
@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .clear-sm-left {
        clear: left;
    }
    .clear-sm-right {
        clear: right;
    }
    .clear-sm-both {
        clear: both;
    }
    .pull-sm-none {
        float: none !important;
    }
    .pull-sm-left {
        float: left;
    }
    .pull-sm-right {
        float: right;
    }
    .align-sm-right {
        text-align: right;
    }
    .align-sm-left {
        text-align: left;
    }
    .align-sm-center {
        text-align: center;
    }
    .hide-sm {
        display: none;
    }
}
@media (min-width: $screen-sm) {
    .hide-sm-up {
        display: none;
    }
}
@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .clear-md-left {
        clear: left;
    }
    .clear-md-right {
        clear: right;
    }
    .clear-md-both {
        clear: both;
    }
    .pull-md-none {
        float: none !important;
    }
    .pull-md-left {
        float: left;
    }
    .pull-md-right {
        float: right;
    }
    .align-md-right {
        text-align: right;
    }
    .align-md-left {
        text-align: left;
    }
    .align-md-center {
        text-align: center;
    }
    .hide-md {
        display: none;
    }
}
@media (min-width: $screen-md-max) {
    .hide-md-up {
        display: none !important;
    }
}
@media (max-width: $screen-md) {
    .hide-md-down {
        display: none;
    }
}
@media (min-width: $screen-lg-min) {
    .clear-lg-left {
        clear: left;
    }
    .clear-lg-right {
        clear: right;
    }
    .clear-lg-both {
        clear: both;
    }
    .pull-lg-none {
        float: none !important;
    }
    .pull-lg-left {
        float: left;
    }
    .pull-lg-right {
        float: right;
    }
    .align-lg-right {
        text-align: right;
    }
    .align-lg-left {
        text-align: left;
    }
    .align-lg-center {
        text-align: center;
    }
    .hide-lg-up {
        display: none;
    }
}


