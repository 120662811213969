body{overflow-x:hidden;}
.home.page {
    div.row.bg-gray {
        .container {
            padding:0;
        }
    }
}
.home.page .split-full-bg .col-md-4.bg-right {
  .list-group-item {
    border:0;
  }
  @media (max-width: $screen-sm-max) {
    .list-group-item {
      border: 1px solid #ddd;
    }
  }
  
}
.metaslider-flex.metaslider {
    top: -2px;
    
    .flexslider {
        margin-bottom: -1px;
        .layer {
          a.btn {
            background-color:color('blue');
            border: 1px solid color('blueTeal');
            background: #004f94;
            border: 1px solid #00aeef;
            border-radius: 8px;
            padding: 1rem 4rem;
            letter-spacing: 1px;
            font-size:2rem !important;
          }
          @media (max-width: $screen-xs-max) {
            a.btn {
              padding: .3rem 3rem;
            }
          }
        }
        @media (max-width: $screen-phone) {
          .msHtmlOverlay {
            .layer:first-child {
              top: 30% !important;
            }
            
          }
        }
        
    }
}

.bg-white-to-gray {
    background: linear-gradient(to right, white 50%, #e5e5e5 50%);
    background-size: 150% 100%;
    background-position: left bottom;
    .container {
          padding-top: 0;
          padding-bottom: 0;
    }
}

.row {
  
  .col-md-8.bg-white {
    
  }
  .col-md-4.bg-gray {
    
  }
  
}
.page-template-template-right-sidebar {
  .split-full-bg {
    .bg-left {
      padding-top:2.5rem;
    }
  }
}
.banner-container {
    position:relative;
    top: -2px;
    height: auto;
    margin-bottom:-2px;
    img {
       width: 100%;
       height:auto;
       max-height:350px;
    }
    >div {
      position: absolute;
      bottom:20px;
      right:0;
      left:0;
      height: auto;
      h1 {
        @include font-size(42);
      }
    }
}
.banner-page-header {
    position:relative;
    z-index:10;
    border:none;
    h1 {
        color:color('white');
    }
    & .page-header {
        border-bottom:none;
        padding-bottom: 100px;
        margin: 125px 0 22px;
    }
}
.content{position:relative;}

.contact-info {
    @include font-size(16);
    line-height:20px;
    padding:20px 0 20px 0;
}
#wpgmza_map {
    margin-top:25px;
}
.employee {
    border-bottom:2px solid #bfbfbf;
    padding:30px 0 30px 0;
}
.employee_name {
    @include font-size(24);
    color: color('blue');
    font-weight:700;
}
.driver-opportunities .gform_wrapper{
  .gform_heading  {
    .gform_title {
      text-align: center;
      margin: 3rem 0;
    }
  }
  .gform_body {
    .gfield_html_formatted {
      color: color('grayDark');
      line-height:26px;
      
      
      a {
        color: color('blueLight');
        text-decoration: underline;
      }
      ol {
        margin-top: 10px;
        li {
          color: color('grayDark');
          list-style-type: disc;
          margin-left: 20px;
          
        }
      }
      label {
        @include font-size(15);
          font-weight: 300;
      }
    }
    
    .gform_drop_area {
        color: color('grayDark');
        .gform_drop_instructions {
          color: color('grayLight');
          margin-top: 5px;
          display: block;
          margin-bottom: 5px;
        }
        .gform_button_select_files {
          border: 1px solid #eee;
          color: color('grayDark');;
          text-align: left;
          margin-left: 0;
          padding-left: 13px;
          padding-top: 5px;
          background: transparent;
        }
        
    }
    
    .gfield_description {
      color: color('grayLight');
      padding:0;
    }
    .gform_page_footer {
      .gform_save_link {
        margin-left:30px;
      }
      .gform_previous_button {
        background-color: color('grayLight');
        font-size: 1.25rem;
        padding: 5px 15px;
        font-weight: 300;
        float:right;
      }
      .gform_button {
        padding: 10px 30px;
        font-size: 1.5rem;
      }
    }
  }
  
  
}
@media (max-width: $screen-sm-min) {
  .metaslider .msHtmlOverlay .layer * {
    font-size: 3.2rem !important;
    line-height: 2.5rem !important;
  }
}
img.sidebar-img {
  width: 350px;
  margin-top: 2rem;
}
@media (max-width: $screen-sm-max) {
  .territories-wrapper {
    >img {
      display: none;
    }
    >div {
      position:inherit;
    }
  }
}
