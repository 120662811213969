/**
 * @description Gravity Forms SASS overides inconjunction with Jay Hoffmann's add_bootstrap_container_class
 *
 * @author Jay Hoffmann (modified by Josh Cranwell)
 * 
 * @source https://jayhoffmann.com/using-gravity-forms-bootstrap-styles/
 *
 * @instructions
 *  - Gravity Form settings: HTML 5 [true], CSS OUTPUT [false].
 *  - Repsonsive column classes must be added to each field in the admin to control inputs .col-[screen-size]-[column-count]
 *  - If all your inputs are the same width then you can @extend .col-xs-12 below line 20.
 */
.hidden_label label {
    display: none;
}
.ginput_container_address {
    .address_city {
        @extend .col-md-5;
        @extend .col-xs-12;
    }
    .address_state {
        @extend .col-md-4;
        @extend .col-xs-12;
        
    }
    .address_zip {
        @extend .col-md-3;
        @extend .col-xs-12;
    }
    .address_line_1 {
       
    }
    .address_line_2 {
       
    }
}

.gfield {
    @extend .col-xs-12;
}

.gform_fields {
    @extend .list-unstyled;
    @extend .row;

    INPUT, SELECT, TEXTAREA {
        @extend .form-control;
        //@extend .col-xs-12;

    }

    TEXTAREA {
        height: auto;

    }

}

.gfield_description {
    @extend .alert;

}

.validation_error {
    @extend .alert;
    @extend .alert-danger;

}

.validation_message {
    @extend .alert;
    @extend .alert-success;

}

.gform_button {
    @extend .btn;
    @extend .btn-primary;

}

.gfield_required {
    color: $alert-danger-text;

}

.gform_wrapper UL.gfield_radio li {
    input[type=radio] {
        position:relative;
        padding: .5rem;
        margin-right: 1.5rem;
    }

}

.gform_wrapper UL.gfield_checkbox li {
    input[type=checkbox] {
        position: relative;
        margin-left: 0px;
        padding: .5rem;
        margin-right: 1.5rem;
    }

}

.gform_validation_container {
    display: none;

}

.gfield_error .ginput_container {
    margin: { bottom: 10px; }

}

@media only screen and (min-width: 641px) {
    .gform_wrapper {
        .ginput_complex.ginput_container_address {
                .ginput_right {
                        padding-left: 0px;
                }
                .ginput_left{
                        padding-left: 0px;
                }
    }
    
    }
}
.gform_wrapper {
    .gfield_date_month input input[type="number"] {
        width: calc(3.5rem + 8px) !important;
    }
    .gform_wrapper .gfield_date_day input input[type="number"] {
        width: calc(3.5rem + 8px) !important;
    }
    .gfield_date_year input[type="number"] {
    width: calc(5rem + 8px) !important;
    }
}