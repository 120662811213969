/*
 * => SEARCH FORM
 * ---------------------------------------------------------------------------*/

.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


.nf-field-element {
    textarea {
        font-size:16px !important;
        padding:0 5px !important;
    }
    & input {
        font-size:16px !important;
        &[type="text"],&[type="email"] {
            height:40px !important;
            padding:0 5px !important;
        }
    }
}
.nf-form-fields-required {
    display:none;
}
